/*
 * External Links
 *
 * Open all external link in new tab
 */

function externalLinks() {
    $('main a:not([target="_blank"])').each(function () {
        if (!$(this).attr('href')) {
            return;
        }

        if (checkExtrenalLink($(this).attr('href'))) {
            $(this).attr('target', '_blank');
        }
    });

    $('main').on('click', 'a:not([target="_blank"])', function (e) {
        if (checkExtrenalLink($(this).attr('href'))) {
            e.preventDefault();

            window.open($(this).attr('href'), '_blank');
        }
    });

    function checkExtrenalLink(href) {
        if (!href) {
            return false;
        }
        let href_split = href.split('/');

        if (
            (href_split[0] == 'http:' || href_split[0] == 'https:') &&
            href_split[2] != window.location.host
        ) {
            return true;
        } else {
            return false;
        }
    }
}

export default externalLinks;
