import AOS from 'aos';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    const bulletTailwind = [
        'block',
        'w-2',
        'h-2',
        'rounded-full',
        'bg-grey-color-3',
        'cursor-pointer',
        'duration-500',
        'hover:bg-brand-color-2',
        'p-3',
        'bg-clip-content',
        'box-content',
    ];
    const bulletActiveTailwind = [
        'cursor-auto',
        '!bg-brand-color-2',
        '!opacity-100',
    ];

    new Swiper('.js-o-slider-a-1', {
        loop: true,
        centeredSlides: true,
        speed: 700,
        watchOverflow: true,
        slideToClickedSlide: true,
        noSwipingSelector: 'a',
        slidesPerView: 'auto',
        spaceBetween: 10,
        breakpoints: {
            992: {
                slidesPerView: 'auto',
                spaceBetween: 20,
            },
        },
        slideActiveClass: 'swiper-slide-active !opacity-100',
        navigation: {
            prevEl: '.js-o-slider-a-1-btn-prev',
            nextEl: '.js-o-slider-a-1-btn-next',
        },
        pagination: {
            el: '.js-o-slider-a-1-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: bulletTailwind.join(' '),
            bulletActiveClass: bulletActiveTailwind.join(' '),
        },
        on: {
            afterInit: function (sw) {
                sw.slides.forEach(function (currentValue, index) {
                    const card = currentValue.querySelector('.m-card-image-a');
                    if (card === null) {
                        return;
                    }

                    card.dataset.aos = 'cards-fade-in-up';
                    card.dataset.aosOffset = '0';
                    card.dataset.aosDuration = '1000';
                    card.dataset.aosDelay = `${++index}00`;
                });
            },
        },
    });
};
