function showAccordion() {
    $('.m-accordion__group-inner').click(function (e) {
        const target = e.target;
        if (target.closest('a')) {
            return;
        }

        e.preventDefault();

        $(this).parent().parent().toggleClass('is-active');
        // eslint-disable-next-line jquery/no-slide
        $(this).find('.m-accordion__text').slideToggle('fast');
        $(this).find('.m-accordion__button-wrapper').slideToggle('fast');
    });
}

export default showAccordion;
