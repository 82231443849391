import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    const bulletTailwind = [
        'block',
        'w-2',
        'h-2',
        'rounded-full',
        'bg-grey-color-3',
        'cursor-pointer',
        'duration-500',
        'hover:bg-brand-color-2',
        'p-3',
        'bg-clip-content',
        'box-content',
    ];
    const bulletActiveTailwind = [
        'cursor-auto',
        '!bg-brand-color-2',
        '!opacity-100',
    ];

    new Swiper('.js-o-slider-b-1', {
        loop: false,
        speed: 700,
        watchOverflow: true,
        slideToClickedSlide: true,
        noSwipingSelector: 'a',
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
            prevEl: '.js-o-slider-b-1-btn-prev',
            nextEl: '.js-o-slider-b-1-btn-next',
        },
        pagination: {
            el: '.js-o-slider-b-1-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: bulletTailwind.join(' '),
            bulletActiveClass: bulletActiveTailwind.join(' '),
        },
        on: {
            afterInit: function (sw) {
                setSliderHeight(sw);
                animateSlides(sw);
            },
            beforeResize: function (sw) {
                sw.$el[0].style.height = '';
            },
            resize: function (sw) {
                console.log('resize');
                setSliderHeight(sw);
            },
            slideChange: function (sw) {
                const slider = sw.$el[0];
                const beginningClasses = '!pr-0 lg:!pr-80';
                const endClasses = '!pl-0 lg:!pl-80';
                const middleClasses = '!pl-0 lg:!pl-40 !pr-0 lg:!pr-40';

                if (sw.isBeginning) {
                    $(slider).removeClass(middleClasses + ' ' + endClasses);
                    $(slider).addClass(beginningClasses);
                } else if (sw.isEnd) {
                    $(slider).removeClass(
                        middleClasses + ' ' + beginningClasses,
                    );
                    $(slider).addClass(endClasses);
                } else {
                    $(slider).removeClass(endClasses + ' ' + beginningClasses);
                    $(slider).addClass(middleClasses);
                }
                //
                animateSlides(sw);
            },
        },
    });

    function animateSlides(sw) {
        //
        const prevSlideClasses = 'py-5 md:py-10';
        const prevSlideInnerClasses = 'py-5 md:py-10';
        const activeSlideClasses = 'py-0';
        const activeSlideInnerClasses = 'py-10 md:py-20';
        //
        const activeSlide = sw.slides[sw.activeIndex];
        const activeSlideInner = activeSlide.querySelector('.item-inner');
        //
        const previousIndex =
            sw.previousIndex !== undefined ? sw.previousIndex : 1;

        sw.slides.forEach((slide) => {
            const prevSlide = slide;
            const prevSlideInner = slide.querySelector('.item-inner');

            $(prevSlide).removeClass(activeSlideClasses);
            $(prevSlide).addClass(prevSlideClasses);
            $(prevSlideInner).removeClass(activeSlideInnerClasses);
            $(prevSlideInner).addClass(prevSlideInnerClasses);
        });

        $(activeSlide).removeClass(prevSlideClasses);
        $(activeSlide).addClass(activeSlideClasses);
        $(activeSlideInner).removeClass(prevSlideInnerClasses);
        $(activeSlideInner).addClass(activeSlideInnerClasses);
        //
    }

    function setSliderHeight(sw) {
        sw.$el[0].style.height = sw.height + 'px';
    }
};
