import MicroModal from 'micromodal';

export default () => {
    /**
     * Save a iframe instance while the Modal is open
     */
    let iframe = '';
    /**
     * Save a vimeo instance while the Modal is open
     */
    let player = '';

    let modal = MicroModal.init({
        onShow: (modal) => {
            if (!modal.closest('[data-module=modalA]')) {
                return;
            }
            $('body').addClass('modal-open');

            /**
             * Create variable with vimeo iframe
             */
            iframe = modal.querySelector('iframe');

            /**
             * When a modal window appears, start the video from the
             * beginning.
             */
            if (iframe) {
                // eslint-disable-next-line no-undef
                player = new Vimeo.Player(iframe);
                player.setCurrentTime(0.01);
                player.play();
            }
        }, // [1]
        onClose: (modal) => {
            if (!modal.closest('[data-module=modalA]')) {
                return;
            }
            $('body').removeClass('modal-open');

            /**
             * Pause when hiding.
             */
            if (iframe) {
                player.pause();
            }
            iframe = '';
            player = '';
        },
        disableScroll: true, // [6]
        awaitOpenAnimation: false, // [8]
        awaitCloseAnimation: true, // [9]
    });
};
