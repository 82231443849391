import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    const bulletTailwind = [
        'block',
        'w-2',
        'h-2',
        'rounded-full',
        'bg-grey-color-3',
        'cursor-pointer',
        'duration-500',
        'hover:bg-brand-color-2',
        'p-3',
        'bg-clip-content',
        'box-content',
    ];
    const bulletActiveTailwind = [
        'cursor-auto',
        '!bg-brand-color-2',
        '!opacity-100',
    ];

    new Swiper('.js-o-cards-b-1', {
        loop: false,
        loopAdditionalSlides: 1,
        speed: 700,
        autoplay: {
            delay: 5000,
        },
        watchOverflow: true,
        slideToClickedSlide: true,
        noSwipingSelector: 'a',
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            639: {
                slidesPerView: 2,
            },
            1023: {
                slidesPerView: 3,
            },
        },
        navigation: {
            prevEl: '.js-o-cards-b-1-btn-prev',
            nextEl: '.js-o-cards-b-1-btn-next',
        },
        pagination: {
            el: '.js-o-cards-b-1-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: bulletTailwind.join(' '),
            bulletActiveClass: bulletActiveTailwind.join(' '),
        },
        on: {
            unlock: function (sw) {
                $('.o-cards-b-1__pagination-controls').removeClass('hidden');
            },
            lock: function (sw) {
                $('.o-cards-b-1__pagination-controls').addClass('hidden');
            },
        },
    });
};
