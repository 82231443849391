import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    new Swiper('.js-o-testimonials-a-1', {
        loop: false,
        speed: 700,
        autoplay: {
            delay: 7000,
        },
        watchOverflow: true,
        slideToClickedSlide: true,
        noSwipingSelector: 'a',
        slidesPerView: 1,
        spaceBetween: 20,
    });
};
